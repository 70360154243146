import React from "react"
import { graphql, navigate } from 'gatsby'
import pageStyles from "./pages.module.css"

export default class ContactPage extends React.Component { 
  state = {
    email: "",
    message: "",
  }

  handleInputChange = event => {
    const target = event.target
    const value = target.value
    const name = target.name
    this.setState({
      [name]: value,
    })
  }
  handleSubmit = event => {
    event.preventDefault();

    const siteData = this.props.data.site.siteMetadata;

    fetch(`${siteData.siteUrl}/api/sendemail`,{
        mode: 'no-cors',
        method: 'POST',
        body: `{"email":"${this.state.email}", "message": "${this.state.message}"}`,
        headers: {
            'Content-Type': 'application/json'
          }
    }).then(response => {
        navigate('/thanks');
        console.log(response.status);
        
      })  
  }
  render() {

    return (
        <div className={pageStyles.page}>
         
        <h2>Contact Me</h2>
         <form id="contactForm" onSubmit={this.handleSubmit}>
          <div className={pageStyles.formSection}>
          <label for="email" className={pageStyles.emailLabel}>Email address</label>
            <input type="text" name="email" placeholder="Enter email"  
            value={this.state.email}
            onChange={this.handleInputChange}
            /> 
          </div>
        
          <div className={pageStyles.formSection}>
          <label for="message" className={pageStyles.messageLabel}>Message</label>
          <textarea className={pageStyles.messageTextarea} name="message" id="message" rows="5" placeholder="Message"  
            value={this.state.message}
            onChange={this.handleInputChange}/>
          </div>
       
        <button type="submit">Submit</button>
      </form>
      </div>
    )
  }
}

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`;